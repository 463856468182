import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'
import Cache from '../utils/cache'
import store from '@/store'
import Config from "@/config/config";

const routes: Array<RouteRecordRaw> = [
    {
        name: 'index',
        path: '/',
        redirect: '/home/dashboard',
        component: Layout,
        children: [
            {
                name: 'homeDashboard',
                path: '/home/dashboard',
                component: () => import('@/views/home/dashboard.vue'),
                meta: {
                    title: '后台管理系统',
                    icon: 's-home',
                    color: '#fd9d21',
                    keepAlive: true
                }
            },
            // {
            //     name: 'purchaseEcharts',
            //     path: '/financeMerchant/purchaseEcharts',
            //     component: () => import('@/views/financeMerchant/purchaseEcharts.vue'),
            //     meta: {
            //         title: '采购管理',
            //         icon: 's-home',
            //         color: '#fd9d21',
            //         keepAlive: true
            //     }
            // },
        ]
    },
    {
        path: "/home/operatingManual",
        component: Layout,
        children: [

            {
                path: '/home/operatingManual',
                name: 'operatingManual',
                component: () => import('@/views/home/operatingManual.vue'),
                meta: {
                    title: '操作手册',
                }
            }
        ]
    },
    {
        path: '/login.html',
        name: 'login',
        component: () => import('@/views/passport/login.vue'),
        meta: {
            title: '登录',
        }
    },
    {
        path: '/forgotPwd.html',
        name: 'forgotPwd',
        component: () => import('@/views/passport/forgotPwd.vue'),
        meta: {
            title: '找回密码',
        }
    },
    {
        path: '/join.html',
        name: 'join',
        component: () => import('@/views/passport/join.vue'),
        meta: {
            title: '加入我们',
        }
    },
    {
        path: '/deal.html',
        name: 'deal',
        component: () => import('@/views/passport/deal.vue'),
        meta: {
            title: '协议',
        }
    },
    {
        path: "/goodsAdmin",
        component: Layout,
        children: [

            {
                path: '/goodsAdmin/uploadGoodsList',
                name: 'uploadGoodsList',
                component: () => import('@/views/goodsAdmin/uploadGoodsList.vue'),
                meta: {
                    title: '商品列表',
                }
            }
        ]
    },
    {
        path: "/procureGoodsAdmin",
        component: Layout,
        children: [
            {
                path: '/procureGoodsAdmin/procuregoodsList',
                name: 'procuregoodsList',
                component: () => import('@/views/procureGoodsAdmin/procuregoodsList.vue'),
                meta: {
                    title: '未下单商品列表',
                }
            },
            {
                path: '/procureGoodsAdmin/procuregoodsOrder',
                name: 'procuregoodsOrder',
                component: () => import('@/views/procureGoodsAdmin/procuregoodsOrder.vue'),
                meta: {
                    title: '采购提交订单',
                }
            },
            {
                path: '/procureGoodsAdmin/procuregoodsOrder',
                name: 'procuregoodsOrder',
                component: () => import('@/views/procureGoodsAdmin/procuregoodsOrder.vue'),
                meta: {
                    title: '已下单商品列表',
                }
            },
            {
                path: '/procureGoodsAdmin/procuregoodsOrdered',
                name: 'procuregoodsOrdered',
                component: () => import('@/views/procureGoodsAdmin/procuregoodsOrdered.vue'),
                meta: {
                    title: '已下单列表',
                }
            },
            {
                path: '/procureGoodsAdmin/procureOrderPay',
                name: 'procureOrderPay',
                component: () => import('@/views/procureGoodsAdmin/procureOrderPay.vue'),
                meta: {
                    title: '提交采购订单',
                }
            },
            {
                path: '/procureGoodsAdmin/orderDetails',
                name: 'orderDetails',
                component: () => import('@/views/procureGoodsAdmin/orderDetails.vue'),
                meta: {
                    title: '采购订单详情',
                }
            },
        ]
    },
    {
        path: "/storeAdmin/accountInfo",
        component: Layout,
        children: [
            {
                path: '/storeAdmin/weChatApply',
                name: 'weChatApply',
                component: () => import('@/views/storeAdmin/weChatApply.vue'),
                meta: {
                    title: '收付通账户',
                }
            },
            {
                path: '/storeAdmin/weChatAccount',
                name: 'weChatAccount',
                component: () => import('@/views/storeAdmin/weChatAccount.vue'),
                meta: {
                    title: '账户信息',
                }
            },
            {
                path: '/storeAdmin/accountInformation',
                name: 'accountInfo',
                component: () => import('@/views/storeAdmin/accountInformation.vue'),
                meta: {
                    title: '普通账户',
                }
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory("/"),
    routes
})

const whiteList = ['/login.html', '/forgotPwd.html', '/join.html', '/deal.html']; //白名单

router.beforeEach(async (to, from, next) => {
    let accessToken = Cache.getCookie(Cache.jicaiToken)
    //当前路由
    //判断标题
    document.title = to.meta.title ? String(to.meta.title) : '商家管理后台';
    const toPath: string = to.path;
    if (accessToken) {
        let user = Cache.getStorage(Cache.jicaiUser);
        if (!store.getters.dictDatas || Object.keys(store.getters.dictDatas).length === 0) {
            await store.dispatch('dict/loadDictDatas')
        }
        if (toPath === '/login.html') {
            next({ path: '/' })
        } else if (!user || !user.id) {
            // 取不到用户就退出登录
            await store.dispatch("user/getInfo").then((res) => {
                if(!res.role || res.role == 1 || res.role == 3) {
                    if(toPath !='/join.html'){
                        next(`/join.html`)
                    }
                }else{
                    next({path: '/'})
                }
            });
        }else if (toPath != '/join.html'&&(store.getters.jcMenuList.length <= 0 || !router.hasRoute(to.name))) {
            await store.dispatch('permissionSupplier/supplierMenuAdd').then(accessedRoutes => {
                next({
                    ...to,
                    replace: true
                })
                return
            })
        } else {
            next()
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            // next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            // window.location.href="/login.html"
            next(`/login.html`)
        }
    }
})

export default router
