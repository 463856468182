import https from '../../utils/http'
import store from "@/store";
import Cache from "@/utils/cache";
import Config from "@/config/config";

const state: any = {
	token: '',
	name: '',
	avatar: '',
}

const mutations = {
	//clear token
	CLEAR_TOKEN: (state) => {
		state.token = ''
	},
	SET_TOKEN: (state, token) => {
		state.token = token
	}
}

const actions = {
	login({ commit }, supplierUserInfo: any) {
		return new Promise((resolve, reject) => {
			https.post("/passport/login", supplierUserInfo, false).then((res: any) => {
				Cache.setCookie(Cache.jicaiToken, res.token, 1);
				commit('SET_TOKEN', res.token)
				resolve(res)
			}).catch((err: any) => {
				reject(err)
			});
		})
	},
	// user logout
	logout({ commit }, state: any) {
		return new Promise((resolve, reject) => {
			https.post("/passport/logout", {}, false).then((res: any) => {
				store.dispatch('permissionSupplier/clearPermission');
				store.dispatch('permissionSupplier/clearSupplierUserList');
				Cache.setStorage(Cache.jicaiUser, null);
				Cache.setCookie(Cache.jicaiToken, null, null);
				Cache.setStorage(Cache.keySupplierList, null)
				store.dispatch('permissionSupplier/supplierMenuDel')
				commit('SET_TOKEN', '')
				store.dispatch('dict/clearDictDatas');
				store.dispatch('permissionSupplier/supplierMenuDel').then(() => {
					resolve('ok')
				})
				window.location.href = `${location.origin}/login.html`
			}).catch((err: any) => {
				reject(err)
			});
		})
	},
	// 获取用户信息
	getInfo({ commit, state }) {
		return new Promise((resolve, reject) => {
			https.get("/passport/getInfo", {}, false, false, null).then((res: any) => {
				Cache.setStorage(Cache.jicaiUser, res);
				resolve(res)
			}).catch((err: any) => {
				reject(err)
			});
		})
	},
	// 通用跳转至登录页
	toLogin({ commit }, state: any) {
		window.location.href = `${location.origin}/login.html`;
	},
	// 通用跳转至登录页
	toJoin({ commit }, merchantId: any) {
		window.location.href = `${location.origin}/join.html`;
	},
	// 通用跳转至首页
	toHome({ commit }, state: any) {
		window.location.href = `${location.origin}/`;
	},
	// 通用跳转至协议
	toDeal({ commit }, state: any) {
		window.open(`${location.origin}/deal.html`);
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
