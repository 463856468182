import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82f979be"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sidebar"
}
const _hoisted_2 = {
  key: 0,
  class: "sidebar-el-menu"
}
const _hoisted_3 = ["index", "onClick"]
const _hoisted_4 = { class: "sidebar-icon" }
const _hoisted_5 = { class: "bj-flexitem-1" }
const _hoisted_6 = {
  key: 1,
  class: "sidebar-menu"
}
const _hoisted_7 = { class: "sidebar-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowRight = _resolveComponent("ArrowRight")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return ($setup.items.length > 0 && $setup.items[0].name != '控制台')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_scrollbar, { "wrap-class": "scrollbar-wrapper" }, {
          default: _withCtx(() => [
            (!$setup.menuShow)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["sidebar-item bj-flex-start", { 'sidebar-active': item.name === $setup.name }]),
                      index: item.id,
                      key: item.id,
                      onClick: ($event: any) => ($setup.sidebarTouch(item))
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("i", {
                          class: _normalizeClass(["iconfont", item.icon ? item.icon : 'icon-index'])
                        }, null, 2)
                      ]),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ArrowRight)
                          ]),
                          _: 1
                        })
                      ])
                    ], 10, _hoisted_3))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_el_menu, {
                    "default-active": "2",
                    class: "el-menu-vertical-demo",
                    collapse: $setup.menuShow,
                    "background-color": "#0eb738",
                    "text-color": "#fff",
                    "active-text-color": "#fff"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item) => {
                        return (_openBlock(), _createBlock(_component_el_sub_menu, {
                          key: item.path,
                          "popper-append-to-body": true,
                          index: item.path
                        }, {
                          title: _withCtx(() => [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("i", {
                                class: _normalizeClass(["iconfont", item.icon ? item.icon : 'icon-index'])
                              }, null, 2)
                            ]),
                            _createElementVNode("span", null, _toDisplayString(item.name), 1)
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (subItem) => {
                              return (_openBlock(), _createElementBlock(_Fragment, null, [
                                (subItem.children &&subItem.children.length > 0)
                                  ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                                      "popper-append-to-body": true,
                                      index: subItem.path,
                                      key: subItem.path
                                    }, {
                                      title: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(subItem.name), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subItem.children, (threeItem, i) => {
                                          return (_openBlock(), _createBlock(_component_el_menu_item, {
                                            key: i,
                                            index: threeItem.path,
                                            onClick: ($event: any) => ($setup.menuTouch(threeItem, item))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(threeItem.name), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["index", "onClick"]))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1032, ["index"]))
                                  : (_openBlock(), _createBlock(_component_el_menu_item, {
                                      index: subItem.path,
                                      key: subItem.path,
                                      onClick: ($event: any) => ($setup.menuTouch(subItem, item))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(subItem.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["index", "onClick"]))
                              ], 64))
                            }), 256))
                          ]),
                          _: 2
                        }, 1032, ["index"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["collapse"])
                ]))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}