const getters: any = {
	device: state => state.app.device,
	jcMenuList: state => state.permissionSupplier.jcMenuList,
	// 数据字典
	dictDatas: state => state.dict.dictDatas,
	permission_routes: state => state.permissionSupplier.routes,
	// token
	token: state => state.user.token,
}
export default getters
