
import { defineComponent, computed, ref, reactive, inject } from "vue";
import Cache from "@/utils/cache";
import { validate } from "@/utils/validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox, FormRules, FormInstance } from "element-plus";
import {
    MoreFilled,
    ArrowLeftBold,
    ArrowDown,
    Lock,
} from "@element-plus/icons-vue";
import identityPop from "@components/popup/identityPop.vue";

export default defineComponent({
    components: {
        MoreFilled,
        ArrowLeftBold,
        ArrowDown,
        identityPop,
        Lock,
    },
    setup() {
        const reload: any = inject("reload");
        const handleExit = () => {
            localStorage.removeItem("token");
        };
        const router = useRouter();
        const store = useStore();
        const userIndentity = computed(() =>
            Cache.getStorage(Cache.keySupplierList) ||
            store.getters.supplierUserList
        );
        const device = computed(() => store.state.app.device);
        const ruleFormRef = ref<FormInstance>();
        let userInfo = computed(() => Cache.getStorage(Cache.jicaiUser));
        const menuShow = computed(
            () => store.state.permissionSupplier.menuShape
        );
        let passwordShow = ref<any>(false);
        // 修改密码参数
        let passwordInfo = ref<any>({
            password: "",
            repassword: "",
        });
        //表单验证
        const rules = reactive<FormRules>({
            password: [
                {
                    required: true,
                    message: "请输入密码",
                    trigger: "blur",
                },
                {
                    min: 4,
                    max: 16,
                    message: "请输入6-20位数字和字母组成",
                    trigger: "blur",
                },
            ],
            repassword: [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value !== passwordInfo.value.password) {
                            callback(new Error("两次输入密码不一致!"));
                        } else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ],
        });
        //退出登录
        let handleDropdown = (item) => {
            if (item == "logout") {
                ElMessageBox.confirm("确定要退出登录", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    store.dispatch("user/logout");
                }).catch(() => {});
            } else {
                passwordInfo.value = {
                    password: "",
                    repassword: "",
                };
                passwordShow.value = true;
            }
        };
        const global: any = inject("global");
        //修改密码
        let submitModifyPwd = async (formEl: FormInstance | undefined) => {
            if (!formEl) return;
            if (!validate.IsPassword(passwordInfo.value.password)) {
                // 4到16位（字母，数字，下划线，减号）
                ElMessage({
                    message: "请输入6到20位数字和字母组成的密码",
                    type: "warning",
                });
                return;
            }
            await formEl.validate((valid, fields) => {
                if (valid) {
                    global.https.post("/passport/modifyPwd", {
                        password: passwordInfo.value.password,
                    }).then((res: any) => {
                        ElMessage({
                            message: "修改成功",
                            type: "success",
                        });
                        passwordShow.value = false;
                        store.dispatch("user/logout");
                        // window.location.href="/login.html"
                    });
                }
            });
        };
        // 身份切换
        const identityPop = ref<any>(null);
        const cutTouch = () => {
            identityPop.value.dialogVisible = true;
        };
        //切换身份回调
        const userAdd = (item:any) => {
            global.https.post("/passport/changeAccountLogin", {
				merchantId: item.merchantId,
                applyStatus:item.applyStatus
			}).then((res: any) => {
				Cache.setCookie(Cache.jicaiToken, res.token, 1);
				Cache.setStorage(Cache.jicaiUser, res);
				store.dispatch("delAllViews");
				store.dispatch("permissionSupplier/supplierMenuAdd");
				if (res.role <= 1 || res.role == 3) {
					store.dispatch("user/toJoin");
				} else {
					ElMessage({
						message: "切换成功",
						type: "success",
					});
					store.dispatch("permissionSupplier/supplierMenuAdd");
					reload();
				}
			});
        };
        const switchTouch = (item) => {
            store.dispatch("permissionSupplier/showAdd", item);
        };
        return {
            handleExit,
            device,
            userInfo,
            menuShow,
            userIndentity,
            passwordShow,
            passwordInfo,
            rules,
            ruleFormRef,
            handleDropdown,
            submitModifyPwd,
            identityPop,
            cutTouch,
            userAdd,
            switchTouch,
        };
    },
});
