
/**
 * @description 侧边菜单栏页面
 */
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Grid } from "@element-plus/icons-vue";
export default {
    components: {
        Grid,
    },
    setup() {
        const convertIcon = () => {
            return `wefwerwer`;
        };
        const router = useRouter();
        const store = useStore();
        /**
         * @description 动态路由采用 curItens
        */
        //总导航列表数据
        const items = computed(() => store.state.permissionSupplier.jcMenuList);
        const name = ref<String>("");
        const collapse = computed(() => store.state.collapse);
        // 监听菜单栏的值变化
        const menuList = computed(() => store.state.permissionSupplier.jcMenuLevel);
        const menuShow = computed(() => store.state.permissionSupplier.menuShape);
        //数据导航菜单变化
        watch(menuList,(newVal, oldVal) => {
			name.value = newVal.name;
		},{ immediate: true, deep: true });
		//菜单点击回调
        const sidebarTouch = (item) => {
            if (item.children[0].children && item.children[0].children.length > 0) {
                router.push(item.children[0].children[0].path);
                //存储到vuex
                store.dispatch("addVisitedViews", item.children[0].children[0]);
            } else {
                store.dispatch("addVisitedViews", item.children[0]);
                router.push(item.children[0].path);
            }
            name.value = item.name;
            store.dispatch("permissionSupplier/levelDevice", item);
        };
        //菜单点击回调
        const menuTouch = (item, info) => {
            store.dispatch("addVisitedViews", item);
            router.push(item.path);
            name.value = info.name;
            store.dispatch("permissionSupplier/levelDevice", info);
        };
        return {
            items,
            name,
            menuShow,
            collapse,
            convertIcon,
            sidebarTouch,
            menuTouch,
        };
    },
    methods: {},
};
