import Cookies from 'js-cookie'

const keys = {
    jicaiUser: 'SUPPLIER_USER',
    jicaiToken: 'SUPPLIER_TOKEN',
    jicaiAccount:'SUPPLIER_ACCOUNT',
    keySupplierList: 'SUPPLIER_LIST'
}
let cacheData = {};

const get = function (key) {
    return cacheData[key]
}

const set = function (key, value) {
    if(value === null || value === undefined){
        delete cacheData[key]
        return;
    }
    cacheData[key] = value
}

const getStorage = function (key) {
    let value = localStorage.getItem(key)
    if (value) {
        return JSON.parse(value);
    }
    return value;
}

const setStorage = function (key, value) {
    if (value === null || value === undefined) {
        localStorage.removeItem(key)
        return;
    }
    value = JSON.stringify(value)
    localStorage.setItem(key, value)
}

const getCookie = function (key) {
    return Cookies.get(key);
}
const setCookie = function (key:any, value:any, days:any) {
    if (value !== null) {
		var inFifteenMinutes = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * days); //8小时过期
		Cookies.set(key, value, { expires: inFifteenMinutes });
    } else {
        Cookies.remove(key)
    }
}
const userMemory = function (key:any, value:any){
    if(value !== null){
        var inFifteenMinutes = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 7); //8小时过期
        Cookies.set(key, value, { expires: inFifteenMinutes });
    }else{
        Cookies.remove(key)
    }
}
export default Object.assign(keys, {
    get,
    set,
    getStorage,
    setStorage,
    getCookie,
    setCookie,
    userMemory
})