import Http from '../../utils/http'


/* 
商品类目列表 
 */
export function type_list(merchantId) {
	return Http.get("/category/type_list", {
		merchantId
	})
}
/* 
商品分类列表 
 */
export function category_list(args) {
	return Http.get("/category/category_list", Object.assign({
		page: 1,
		size: 10
	}, args))
}
/* 
根据父ID，获取子分类列表 （只含下一级，不包含孙节点等） 
 */
export function categoryList_byParentId(parentId, categoryTypeId) {
	return Http.get("/category/categoryList_byParentId", {
		parentId,
		categoryTypeId
	})
}

export function treelist() {
	return Http.get("/category/treelist")
}

export function plist(args) {
	return Http.get("/category/plist", Object.assign({
		page: 1,
		size: 1000
	}, args))
}

export function plistByParentId(parentId) {
	return Http.get("/category/plistByParentId", {
		parentId
	})
}

export function ptreelist(categoryTypeId) {
	return Http.get("/category/ptreelist", {
		categoryTypeId
	})
}

export function ptreelist_yytc(categoryTypeId) {
	return Http.get("/category/ptreelist_yytc", {
		categoryTypeId
	})
}


export function ptreelistByMerchantId(merchantId) {
	return Http.get("/category/ptreelistByMerchantId", {
		merchantId
	})
}





export function pinfo(id) {
	return Http.get("/category/pinfo", {
		id: id
	})
}

export function psave_type(model) {
	return Http.post("/category/psave_type", model)
}
/* 
 商品分类保存
 */
export function psave_category(model) {
	return Http.post("/category/psave_category", model)
}

export function pdelete(id) {
	return Http.post("/category/pdelete", {
		id: id
	})
}